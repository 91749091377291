import { aemAxiosInstance, apim } from "@/constants/api"
import { apiBaseUrl, apimSubscriptionKey } from "@/constants/index"
import {
  activateUserFactorUrl,
  submitDealershipUrl,
  sendOTPUrl,
  submitLeadGenerationUrl,
} from "./constants"
import { getAccessToken } from "@/utils/helper"
import { store } from "@/store"
import { checkDuplicacy } from "@/store/features/genericSlice"

export const submitProductEnquiry = async payload => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.post(
        `${apiBaseUrl}${submitDealershipUrl}`,
        payload
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

export const activateUserFactor = async payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apim.post(
        `${apiBaseUrl}${activateUserFactorUrl}`,
        payload
      )
      resolve(response)
    } catch (error) {
      if (
        error?.response?.status === 403 ||
        error?.response?.status === 429 ||
        error?.response?.status === 404
      )
        resolve(error?.response)
      else reject(error)
    }
  })
}

export const checkNumberExists = async mobileNumber => {
  let numberExists = false
  let factorId, userId
  await store.dispatch(checkDuplicacy(mobileNumber)).then(res => {
    if (
      res?.payload?.statusCode == 400 &&
      res?.payload?.message === "USER_EXIST"
    ) {
      localStorage.setItem("numberAlreadyExists", mobileNumber)
      numberExists = true
    } else {
      localStorage.removeItem("numberAlreadyExists")
      numberExists = false
      factorId = res?.payload?.data?.userFactor?.id
      userId = res?.payload?.data?.user?.id
    }
  })
  return { numberExists, factorId, userId }
}
export const submitLeadGeneration = async ({payload, isKECForm=false}) => {
  let accessToken = null
  accessToken = getAccessToken()?.token
  const url = isKECForm? `${submitLeadGenerationUrl}?storeLeads=KEC_${payload.city}`:`${submitLeadGenerationUrl}?festival=diwali`
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.post(
        `${apiBaseUrl}${url}`,
        payload
      )
      resolve(content)
    } catch (error) {
      resolve(error)
    }
  })
}
